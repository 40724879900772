###
#
# @file profile
#
###

class Profile

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:                 'body'
      btn_upload_avatar:    '.btn-upload-avatar'
      avatar_input_file:    '#user_user_photo'
      avatar_image:         '#avatar_image'
      delete_account_field: '#delete_account_field'

    @app.register_turbolinks_load_handler @init

    $(document).on 'click', @el.btn_upload_avatar, @input_file_click
    $(document).on 'change', @el.avatar_input_file, @read_url
    $(document).on 'keyup', @el.delete_account_field, @validate_delete_field

  init: =>
    unless $(@el.body).hasClass 'users-registrations'
      return

  input_file_click: (e)=>
    e.preventDefault()
    $(@el.avatar_input_file).click()

  read_url: (e)=>
    if e.currentTarget.files && e.currentTarget.files[0]
      reader = new FileReader()
      reader.onload = (e)->
        $("#avatar_image").css "background-image", "url(#{e.target.result})"
      reader.readAsDataURL(e.currentTarget.files[0])

  validate_delete_field: (e)=>
    value = $(@el.delete_account_field).val()

    if value == "BORRAR"
      $('.btn-delete-account').removeClass 'disabled'
    else
      $('.btn-delete-account').addClass 'disabled'

module.exports = Profile
