###
#
# @file payment
#
###

class Payment

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:               'body'
      input:              'input'
      error_msg:          '#coupon-error-msg'

    @app.register_turbolinks_load_handler @init

    $(document).on 'click', '[data-cta-validate-coupon]', @validate_coupon

  init: =>
    unless $(@el.body).hasClass 'applications'
      return


  validate_coupon: (e)=>
    payload = {
      app_id: $(e.currentTarget).data 'cta-validate-coupon'
      code: $('#cupon-code').val()
    }
    token = $('meta[name=csrf-token]').attr('content')
    btn_txt_original = $(e.currentTarget).data 'btn-text'
    btn_txt_validating = $(e.currentTarget).data 'btn-text-validating'

    $(e.currentTarget).text btn_txt_validating

    new Promise (resolve, reject)=>
      payload = JSON.parse JSON.stringify payload
      $.ajax
        url: "/applications/#{payload.app_id}/payment/coupon"
        type: "POST"
        headers:
          'X-CSRF-Token': token #Add the token manually
        data: payload
        dataType: 'json'
        success: (payload)=>
          console.log payload
          if payload.status == 500
            $(@el.error_msg).text payload.errors
            $(@el.error_msg).slideDown()
            window.setTimeout (=>
              $(@el.error_msg).slideUp()
              $(@el.error_msg).text ''
              $('[data-cta-validate-coupon]').text btn_txt_original
              $('#cupon-code').val ''
              return
            ), 4000
          else
            window.location.href = payload.redirect_to
            resolve()
        error: @push_failure.bind this, reject


  push_payment_data: (payload)=>
    token = $('meta[name=csrf-token]').attr('content')
    new Promise (resolve, reject)=>
      payload = JSON.parse JSON.stringify payload
      $.ajax
        url: "/applications/#{payload.applicationId}/payment/process"
        type: "POST"
        headers:
          'X-CSRF-Token': token #Add the token manually
        data: payload
        dataType: 'json'
        success: (payload)=>
          window.location.href = payload.redirect_to
          resolve()
        error: @push_failure.bind this, reject


  push_failure: (reject, jqXHR)=>
    console.log jqXHR
    reject('There was an unexpected error')


module.exports = Payment
