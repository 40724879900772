import { DirectUpload } from "@rails/activestorage"

class MyUploader {
  constructor(file, url, dropzone) {

    // Define DOM elements
    this.dropzone_dom = dropzone
    this.dropzone_loader = this.dropzone_dom.find('.progress-load')
    this.dropzone_loader_txt = this.dropzone_dom.find('.status')
    this.dropzone_preview = this.dropzone_dom.find('.dz-preview')
    this.dropzone_remove = this.dropzone_dom.find('.dz-remove')
    this.meta_field = this.dropzone_dom.parents('.form-field').find('input[type="hidden"]')
    this.meta_field_files = [] // used to store the val() of each field

    if (this.dropzone_dom.data('model')) {
      if (this.dropzone_dom.data('model') === "user") {
        this.model = "user[user_real_id][]"
      }
    } else {
      this.model = "application[files][]"
    }

    this.upload = new DirectUpload(file, url, this)
    this.upload.create((error, blob) => {

      if (error) {
        //alert("Hubo un error subiendo tu archivo.")
        this.dropzone_dom.addClass("dropzone-area--has-error")
      } else {
        //console.log(blob)

        // create hidden fields for attachments
        var hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden")
        hiddenField.setAttribute("value", blob.signed_id)
        hiddenField.name =  this.model
        document.querySelector('form').appendChild(hiddenField)


        //this.meta_field.val(blob.id)
        if ( this.meta_field.val() === '' ) {
          this.meta_field.val(blob.id)
        } else {
          this.meta_field.val( `${this.meta_field.val()}, ${blob.id}` )
        }

        this.meta_field.attr('data-file-blob-id', blob.id)

        // Show preview
        this.dropzone_dom.addClass('preview-mode')
        this.dropzone_remove.addClass('button delete small')
        this.dropzone_preview.show()

        // iterate over each [data-file-blob-id] and push the val() to meta_field_files array
        $("[data-file-blob-id]").each(function(i,e) {
          var field_required = $(e).data('blob-required');
          if ( field_required ) {
            this.meta_field_files.push($(e).attr("data-file-blob-id"))
          }
        }.bind(this))

        // var arraySet = new Set( this.meta_field_files )
        //
        // if (!arraySet.has("")) {
        //   this.dropzone_dom.parents('.fieldset').find('.button--next').removeClass('disabled')
        //   //$(".button--next").removeClass('disabled')
        //   $('body').removeClass('uploads-pending')
        // }

      }
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    var progress = Math.round((event.loaded * 100) / event.total)
    this.dropzone_loader.css('width', progress + '%')
    this.dropzone_loader_txt.addClass('uploading')
    this.dropzone_loader_txt.html(`Subiendo documento: <strong>${progress}%</strong>`)
  }
}

export default MyUploader;
