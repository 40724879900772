###
#
# @file payment-ptp
#
###

class PaymentPlaceToPay

  constructor: (@app, config) ->
    @auth = {}
    @app.register_turbolinks_load_handler @init

  init: =>
    return unless $("body.page-payment").length

    location = new URL(window.location.href)

    if (location.searchParams.has('val_ptp'))
      @getPaymentInformation()
    
    $(document).on "click", "#btn-placetopay", @handler_placetopay


  getPaymentInformation: ()=>
    options = {
      "async": true
      "crossDomain": true
      "url": $('meta[name="ptp_request_payment"]').attr("content")
      "method": "POST"
      "headers": {
        "Content-Type": "application/json"
      }
      "processData": false
    }

    $.ajax(options).done( (response)=>
      if response.error == "rejected"
        alert "Tu intento de pago fué CANCELADO ó RECHAZADO. Intenta utilizando otro método de pago."
      if response.error == "pending"
        alert "Tu pago se encuentra PENDIENTE. Espera 10 minutos a que nuestro sistema valide el pago. Recibirás un email con el resultado de la validación."
      if response.error == "error"
        alert "Hubo un error desconocido con tu pago. Escríbenos a ayuda@servidocpr.com para informar el problema."
      window.location.href = response.redirect_to
    )

  handler_placetopay: (e)=>
    e.preventDefault()
    $(e.currentTarget).text "Iniciando proceso..."
    $(e.currentTarget).addClass "disabled"

    options = {
      "async": true
      "crossDomain": true
      "url": $('meta[name="ptp_auth_url"]').attr("content")
      "method": "POST"
      "headers": {
        "Content-Type": "application/json"
      }
      "processData": false
    }

    $.ajax(options).done( (response)=>
      window.open(response.processUrl, "_self")
    )


module.exports = PaymentPlaceToPay
