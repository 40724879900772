###
#
# @file payment-athmovil
#
###

class PaymentATHMovil

  constructor: (@app, config) ->
    # @application_price = $('meta[name="application_price"]').attr("content")
    # @application_id = $('meta[name="application_id"]').attr("content")
    # @application_description = $('meta[name="application_description"]').attr("content")
    # @application_type = $('meta[name="application_type"]').attr("content")
    # @application_user_email = $('meta[name="application_user_email"]').attr("content")
    # @application_user_id = $('meta[name="application_user_id"]').attr("content")
    # @ath_public_token = $('meta[name="ath_public_token"]').attr("content")
    # @ath_env = $('meta[name="ath_env"]').attr("content")

    # @ATHM_Data = {
    #   env: @ath_env,
    #   publicToken: @ath_public_token,
    #   timeout: 120,
    #   theme: 'btn',
    #   lang: 'es',
    #   total: @application_price,
    #   tax: 1.00,
    #   subtotal: @application_price,
    #   metadata1: @application_id,
    #   metadata2: @application_user_id,
    #   items: [
    #       {
    #         "name": "Pago de Solicitud ##{@application_id}",
    #         "description": "#{@application_description}",
    #         "quantity": "1",
    #         "price": "#{@application_price}",
    #         "tax": "1.00",
    #         "metadata": "#{@application_user_email}"
    #       }
    #   ],
    #   onCompletedPayment: (response)=>
    #     @payment_completed(response)

    #   onCancelledPayment: (response)=>
    #     @payment_cancelled(response)

    #   onExpiredPayment: (response)=>
    #     @payment_expired(response)
    # }

    @app.register_turbolinks_load_handler @init

  init: =>
    return unless $("body.page-payment").length

  payment_completed: (response)=>
    _UD.components.Payment.push_payment_data({
      referenceNumber: "#{response.referenceNumber}",
      total: "#{response.total}",
      applicationId: "#{response.metadata1}",
      gateway: "ATHMovil"
    })

  payment_cancelled: (response)=>
    alert "El pago ha sido cancelado"

  payment_expired: (response)=>
    alert "El tiempo disponible para el pago ha expirado. Recargue la página e intente nuevamente."

module.exports = PaymentATHMovil
