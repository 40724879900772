###
#
# @file notifications
#
###


class Notifications

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:               'body'
      input:              'input'

    @app.register_turbolinks_load_handler @init

  init: =>
    if $('.flash-notification').length > 0
      window.setTimeout (->
        $('.flash-notification').addClass 'animate-close'
        return
      ), 5000


module.exports = Notifications
