###
#
# @file payment-stripe
#
###

class PaymentStripe

  constructor: (@app, config) ->
    @app.register_turbolinks_load_handler @init

  init: =>
    return unless $("body.page-payment").length

    @application_price = $('meta[name="application_price"]').attr("content")
    @application_id = $('meta[name="application_id"]').attr("content")
    @application_description = $('meta[name="application_description"]').attr("content")
    @application_type = $('meta[name="application_type"]').attr("content")
    @application_user_email = $('meta[name="application_user_email"]').attr("content")
    @application_user_id = $('meta[name="application_user_id"]').attr("content")
    @application_user_country = $('meta[name="application_user_country"]').attr("content")
    @application_user_currency = $('meta[name="application_user_currency"]').attr("content")
    @application_user_locale = $('meta[name="application_user_locale"]').attr("content")
    @stripe_public_key = $('meta[name="stripe_public_key"]').attr("content")

    @handler = StripeCheckout.configure({
      locale: @application_user_locale,
      key: @stripe_public_key,
      token: (token, arg)=>
        $("#stripeToken").val(token.id);
        $("#stripeEmail").val(token.email);
        $("#stripe_form").submit();
    })

    $(document).on "click", "#btn-stripe", @handler_stripe


  handler_stripe: (e)=>
    @handler.open({
      name: "Pago a #{@application_user_country}",
      description: "Concepto: #{@application_type}",
      amount: (parseFloat(@application_price, 2) * 100),
      currency: @application_user_currency,
    })
    e.preventDefault()


module.exports = PaymentStripe
