###
#
# @file application
#
###

class Application

  constructor: (@app, config) ->

    ##
    # DOM Elements
    # @type {string}
    ##
    @el =
      body:               'body'
      input:              'input'

    @drive_lic_inputs = "[name='application[metadata][oidos]'], [name='application[metadata][brazos]'], [name='application[metadata][piernas]'], [name='application[metadata][condicion_inconciencia]'], [name='application[metadata][corazon]'], [name='application[metadata][marcapasos]'], [name='application[metadata][protesis]']"
    @drive_lic_modal_displayed = false
    @current_form = ""

    $(document).on 'click', '[data-application-notes]', @toggle_doctor_notes
    $(document).on 'click', '[data-application-next-step]', @application_next_step
    $(document).on 'click', '[data-application-prev-step]', @application_prev_step
    $(document).on 'click', '[data-application-save-exit]', @save_and_exit
    $(document).on 'click', 'input[value="CMP (BMP más función renal y hepatica)"]', @labs_cmp_toggle_bmp
    $(document).on 'click', 'input[value="BMP (Azúcar en sangre y electrolitos del cuerpo)"]', @labs_bmp_on_click
    $(document).on 'change', '[data-selector="certificado_medico_date_from"]', @certificado_medico_date_from
    $(document).on 'keydown, blur', '[data-selector="certificado_medico_quantity_days"]', @certificado_medico_quantity_days
    $(document).on 'change', '[data-selector="certificado_medico_quantity_days"]', @certificado_medico_get_range_days
    $(document).on 'click', '[data-bmi-calc]', @calculate_bmi
    $(document).on "change", @drive_lic_inputs, @drive_license_conditions

    @app.register_turbolinks_load_handler @init

  init: =>
    unless $(@el.body).hasClass 'applications'
      return
    @calendar_date = $('[data-selector="certificado_medico_date_from"]').val() if $('[data-selector="certificado_medico_date_from"]').length == 1

    @set_certificado_medico_quantity_days() if $('[data-selector="certificado_medico_quantity_days"]').length == 1

    @certificado_medico_get_range_days() if $('[data-selector="certificado_medico_date_from"]').length == 1 && $('[data-selector="certificado_medico_date_from"]').val() != ''
    @certificado_medico_get_range_days() if $('[data-selector="certificado_medico_quantity_days"]').length == 1 && $('[data-selector="certificado_medico_quantity_days"]').val() != ''

    @form_fields_visible_handler()

    @current_form = $("input[name='application[form_type]']").val()


  calculate_bmi: (e)=>
    e.preventDefault()

    height_ft_dom = document.getElementById('height_ft').value
    height_in_dom = document.getElementById('height_in').value
    weightLbs = document.getElementById('weight').value

    if height_ft_dom != '' and height_in_dom != '' and weightLbs != ''

      # Get the values of height and weight
      feet = parseFloat(document.getElementById('height_ft').value)
      inches = parseFloat(document.getElementById('height_in').value)
      height = (feet * 12 + inches) * 0.0254
      heightInches = (feet * 12 + inches)
      # convert height to meters
      weight = parseFloat(weightLbs) * 0.453592
      # convert weight to kg
      # Calculate the BMI
      bmi = weight / height ** 2
      # Display the result
      result = document.getElementById('bmi_result')
      result.value = bmi.toFixed(2)
      result_txt = document.getElementById('bmi_text_result')

      if result and result_txt
        result_txt.innerHTML = ""
        
        # Classify the BMI
        if bmi < 18.5
          result_txt.innerHTML += '<p>Tu BMI indica que tu peso está bajo lo normal.</p>'
        else if bmi >= 18.5 and bmi <= 24.9
          result_txt.innerHTML += '<p>Tu BMI indica que tu peso es normal.</p>'
        else if bmi >= 25 and bmi <= 29.9
          result_txt.innerHTML += '<p>Tu BMI indica que estás con sobrepeso.</p>'
        else if bmi >= 30
          result_txt.innerHTML += '<p>Tu BMI indica que tu peso está en parámetros de obesidad.</p>'

      # If BoyScouts, calculate if meets requirements
      if $(@.el.body).hasClass 'doctors-applications'
        dom_total_inches = document.getElementById('height_inches')
        meet_requirements = document.getElementById('meets_height_weight_requirements')

        $('#bmi_result').parents('.form-field').addClass 'form-field--is-filled'

        dom_total_inches.value = heightInches

        if @meetsRequirements(heightInches, weightLbs)
          console.log "test"
          meet_requirements.value = "Sí"
        else 
          meet_requirements.value = "No"
    
    else 
      alert "Ingrese su altura y peso antes de continuar."


  meetsRequirements: (height, weight) =>
    requirements = [
      { height: 60, max_weight: 166 },
      { height: 61, max_weight: 172 },
      { height: 62, max_weight: 178 },
      { height: 63, max_weight: 183 },
      { height: 64, max_weight: 189 },
      { height: 65, max_weight: 195 },
      { height: 66, max_weight: 201 },
      { height: 67, max_weight: 207 },
      { height: 68, max_weight: 214 },
      { height: 69, max_weight: 220 },
      { height: 70, max_weight: 226 },
      { height: 71, max_weight: 233 },
      { height: 72, max_weight: 239 },
      { height: 73, max_weight: 246 },
      { height: 74, max_weight: 252 },
      { height: 75, max_weight: 260 },
      { height: 76, max_weight: 267 },
      { height: 77, max_weight: 274 },
      { height: 78, max_weight: 281 },
    ]

    if height >= 79
      if weight <= 295 
        return true
      else 
        return false 
    else 
      for entry in requirements
        if height is entry.height and weight <= entry.max_weight
          return true
      false



  form_fields_visible_handler: =>
    $(document).on 'change', @el.input, =>
      $('[data-field-visible="false"]').each (index, elem)->
        selector_target = $(elem).attr 'data-field-selector'
        selector_val = $(elem).attr 'data-field-value'
        $selector_dom = $("[name='application[metadata][#{selector_target}]']:checked")

        if $selector_dom.val() == selector_val
          $(elem).slideDown()

          # Enable required for date field
          $(elem).find("[data-validator='greater_than_21_years']").prop('required', true)

          $(elem).find('[data-dropzone]').addClass 'required'
          $(elem).find('[data-dropzone]').parent('.form-field').find('[data-file-blob-id]').attr('data-blob-required', true)
        else
          $(elem).hide()

          # Disable required for date field
          $(elem).find("[data-validator='greater_than_21_years']").prop('required', false)

          $(elem).find('[data-dropzone]').removeClass 'required'
          $(elem).find('[data-dropzone]').parent('.form-field').find('[data-file-blob-id]').attr('data-blob-required', false)


  toggle_doctor_notes: (e)=>
    e.preventDefault()
    $(e.currentTarget).toggleClass 'expanded'
    $(e.currentTarget).parents('.results-table-item').find('.item-doctor-notes').slideToggle()




  application_next_step: (e)=>
    e.preventDefault()
    current_step = $(e.currentTarget).data 'application-next-step'
    next_step = $(e.currentTarget).data 'application-next-step'
    fieldset = $(e.currentTarget).parents('.fieldset')
    current_fieldset = $("[data-fieldset-step=#{current_step}]")

    if @validate_fieldset(fieldset)
      $("[data-fieldset-step=#{current_step - 1}]").hide()
      $("[data-fieldset-step=#{next_step}]").show()
      @scroll_to_top()
      @set_sidebar_current_step next_step

      if current_fieldset.find('.dropzone-area.required').length > 0 && current_fieldset.find('.dropzone-area.required').is(":visible")
        current_fieldset.find('.button--next').addClass 'disabled'



  application_prev_step: (e)=>
    e.preventDefault()

    current_step = $(e.currentTarget).data 'application-prev-step'
    prev_step = $(e.currentTarget).data 'application-prev-step'

    $("[data-fieldset-step=#{current_step + 1}]").hide()
    $("[data-fieldset-step=#{prev_step}]").show()

    @scroll_to_top()
    @set_sidebar_current_step prev_step

    $(".button--next").removeClass 'disabled'


  set_sidebar_current_step: (current)=>
    $(".submit-sidebar li").removeClass 'current'
    $(".submit-sidebar li:nth-child(#{current})").addClass 'completed'
    $(".submit-sidebar li:nth-child(#{current+1})").addClass 'current'



  scroll_to_top: =>
    target = $("#application_top")
    $('html, body').animate({
      scrollTop: target.offset().top
    }, 1000);


  validate_fieldset: (fieldset)=>
    $fieldset = fieldset

    # Validate each input inside the fieldset
    $fieldset.find('input, select, textarea').each (i, el)=>
      # console.log "#{i} - #{el.id}"
      if el.id
        $(".application-form").foundation 'validateInput', $("##{el.id}")

    # Check if the fieldset contains invalid fields before proceed
    if $fieldset.find('.is-invalid-label').length > 0
      false
    else
      true


  save_and_exit: (e)=>
    e.preventDefault()
    $('.application-form').find('input, select, textarea').removeAttr 'required'
    $('[name="application[action]"]').val "draft"
    $('.application-form').submit()


  labs_cmp_toggle_bmp: (e)=>
    if ( $("input[value='CMP (BMP más función renal y hepatica)']").is(':checked') )
      $("input[value='BMP (Azúcar en sangre y electrolitos del cuerpo)']").prop( "checked", false )

  labs_bmp_on_click: (e)=>
    if ( $("input[value='CMP (BMP más función renal y hepatica)']").is(':checked') )
      $("input[value='BMP (Azúcar en sangre y electrolitos del cuerpo)']").prop( "checked", false )


  certificado_medico_date_from: (e)=>
    date_today = Date.now()
    date_selected = new Date(e.target.value)
    one_day = 24 * 60 * 60 * 1000
    two_days = 48 * 60 * 60 * 1000

    if $('body').hasClass 'doctors-applications'
      @certificado_medico_get_range_days()
    else
      if ((date_today - date_selected) > two_days)
        alert "La fecha seleccionada NO puede ser mayor a la de ayer."
        $('[data-selector="certificado_medico_date_from"]').val @calendar_date
        return false
      if ((date_selected - date_today) > one_day)
        alert "La fecha seleccionada NO puede ser mayor a la de mañana."
        $('[data-selector="certificado_medico_date_from"]').val @calendar_date
        return false
      else
        @certificado_medico_get_range_days()
      

  set_certificado_medico_quantity_days: ()=>
    $('[data-selector="certificado_medico_quantity_days"]').attr 'min', 1
    $('[data-selector="certificado_medico_quantity_days"]').attr 'max', 14

  certificado_medico_quantity_days: (e)=>
    qty = $('[data-selector="certificado_medico_quantity_days"]').val()
    if qty > 14
      alert("No puedes exceder los 14 dias de licencia")
      $('[data-selector="certificado_medico_quantity_days"]').val('14')

  certificado_medico_get_range_days: (e)=>
    payload = {
      date_from: $('[data-selector="certificado_medico_date_from"]').val()
      quantity_days: $('[data-selector="certificado_medico_quantity_days"]').val()
    }
    token = $('meta[name=csrf-token]').attr('content')

    new Promise (resolve, reject)=>
      payload = JSON.parse JSON.stringify payload
      $.ajax
        url: "/certificado-medico/range-days"
        type: "POST"
        headers:
          'X-CSRF-Token': token #Add the token manually
        data: payload
        dataType: 'json'
        success: (payload)=>
          $('[data-selector="certificado-medico-dates-confirmation"]').html(payload.message)
          resolve()
        error: @push_failure.bind this, reject

  push_failure: (reject, jqXHR)=>
    console.log jqXHR
    reject('There was an unexpected error')
  
  drive_license_conditions: (e)=>
    if !@drive_lic_modal_displayed && @current_form == "licencia_de_conducir"
      $('#modalCescoForm').foundation 'open' if $(e.currentTarget).val() == "Sí" 

module.exports = Application
